import * as React from "react";
import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import shuffle from "lodash/shuffle";
import logo from "@app/assets/img/logo.svg";
import loginBackground from "@app/assets/img/raleigh.jpg";
import loginBackground2 from "@app/assets/img/raleigh2.jpg";
import loginBackground3 from "@app/assets/img/raleigh3.jpeg";
import Session from "@app/util/Session";
const backgroundImages = [loginBackground, loginBackground2, loginBackground3];
const backgroundImage = shuffle(backgroundImages)[0];
const LoginForm = () => {
    var _a;
    React.useEffect(() => {
        var _a;
        if ((_a = Session.activeUser) === null || _a === void 0 ? void 0 : _a.email) {
            window.location.href = "/logout/";
        }
    }, []);
    if ((_a = Session.activeUser) === null || _a === void 0 ? void 0 : _a.email) {
        return null;
    }
    return (React.createElement(Grid, { container: true, spacing: 0, style: {
            height: "100%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
        } },
        React.createElement(Grid, { item: true, lg: 7, sm: 6, xl: 9, xs: 3 }),
        React.createElement(Grid, { item: true, lg: 5, sm: 6, xl: 3, xs: 9 },
            React.createElement(Paper, { elevation: 4, style: {
                    height: "100%",
                    color: "white",
                    background: "rgba(0, 0, 0, 0.75)",
                } },
                React.createElement(Box, { p: 4 },
                    React.createElement(Grid, { container: true, spacing: 8 },
                        React.createElement(Grid, { item: true, style: { textAlign: "center" }, xs: 12 },
                            React.createElement("img", { alt: "logo", src: logo, style: { maxHeight: "160px", filter: "brightness(0) invert(1)" } })),
                        React.createElement(Grid, { item: true, style: { textAlign: "center" }, xs: 12 },
                            React.createElement(Typography, { variant: "h6" }, "Sign in with your DASH account.")),
                        React.createElement(Grid, { item: true, style: { textAlign: "center" }, xs: 12 },
                            React.createElement(Button, { color: "primary", component: Link, href: LOGIN_URL, variant: "contained" }, "Log In with Google"))))))));
};
export default LoginForm;
