import config from "../../../../config.json";
const AppConfig = {
    themeColors: {
        primary: "#0d8dba",
        secondary: "#fb5058",
        success: "#439754",
        danger: "#fb5058",
        warning: "#ffc100",
        info: "#0d8dba",
        dark: "#464D69",
        default: "#FAFAFA",
        greyLighten: "#A5A7B2",
        grey: "#677080",
        white: "#FFFFFF",
        blue: "#0d8dba",
        lightblue: "#71b7d3",
        lightestblue: "#b5d9e8",
        red: "#fb5058",
        lightred: "#ffa8ac",
        yellow: "#ffc100",
        green: "#388E3C",
    },
    fonts: {
        primary: "Nunito",
    },
    validators: {
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    },
    slackScopes: config.slack_scopes,
    defaultIconUrl: "https://reappdata.global.ssl.fastly.net/site_data/myrealtorkicksass/staff_pictures/pic-49204-9e8706_sm_square.jpg",
    env: {
        sentryDSN: process.env["SENTRY_DSN"],
        muiPremiumLicenseKey: process.env["MUI_PREMIUM_LICENSE_KEY"],
        loginUrl: "/auth/login/google-oauth2/",
        version: process.env["VERSION"],
    },
    timezone: "America/New_York",
    rootElement: null,
};
console.log("MUI_PREMIUM_LICENSE_KEY", AppConfig.env.muiPremiumLicenseKey);
export const CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS = {
    title: "Are you sure?",
    description: "You won't be able to undo this action.",
    confirmationText: "Delete",
    confirmationButtonProps: {
        color: "error",
        variant: "contained",
        tabIndex: -1,
    },
    cancellationButtonProps: {
        tabIndex: -1,
    },
    dialogProps: {
        maxWidth: "xs",
    },
};
export const CONFIRMATION_DIALOG_ERROR_DEFAULT_OPTIONS = {
    title: "An error occurred",
    confirmationText: "OK",
    confirmationButtonProps: {
        tabIndex: -1,
    },
};
export const IGNORABLE_ERRORS = [
    "AbortError",
    "AxiosError",
    "Failed to fetch",
    "NetworkError when attempting to fetch resource",
    "Non-Error promise rejection captured",
];
export default AppConfig;
