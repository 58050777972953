import * as React from "react";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import WindowsScrollbarWrapper from "@app/common/layout/WindowsScrollbarWrapper";
import theme from "@app/common/theme";
import { DialogServiceProvider } from "@app/hoc/withDialog";
import LoginForm from "./LoginForm";
const App = () => {
    const notistackRef = React.useRef(null);
    const onNotistackDismiss = React.useCallback((key) => () => {
        const ref = notistackRef.current;
        if (ref) {
            ref.closeSnackbar(key);
        }
    }, []);
    const renderNotistackDismiss = React.useCallback((key) => {
        return (React.createElement(IconButton, { onClick: onNotistackDismiss(key), tabIndex: -1 },
            React.createElement(Close, null)));
    }, [onNotistackDismiss]);
    return (React.createElement(WindowsScrollbarWrapper, { id: "windows-sidebar-wrapper" },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(SnackbarProvider, { ref: notistackRef, maxSnack: 3, action: renderNotistackDismiss },
                React.createElement(DialogServiceProvider, null,
                    React.createElement(LoginForm, null))))));
};
export default App;
