import * as React from "react";
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";
import hoistNonReactStatics from "hoist-non-react-statics";
import CoreDialog from "@app/common/CoreDialog";
const DialogServiceContext = React.createContext({
    isOpen: false,
    props: {},
    showDialog: (_) => { },
    showErrorDialog: (_) => { },
    hideDialog: () => { },
});
export class DialogServiceProvider extends React.Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "showDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (props) => {
                this.setState({
                    isOpen: true,
                    props: props !== null && props !== void 0 ? props : {},
                });
            }
        });
        Object.defineProperty(this, "showErrorDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                var _a, _b, _c, _d;
                this.setState({
                    isOpen: true,
                    props: {
                        title: ((_a = error.result) === null || _a === void 0 ? void 0 : _a.status) || ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) || error.status || "An error occurred",
                        text: error instanceof Error
                            ? String(error)
                            : JSON.stringify(((_c = error.result) === null || _c === void 0 ? void 0 : _c.data) || ((_d = error.response) === null || _d === void 0 ? void 0 : _d.data) || error.data || error, null, 2),
                        cancelButtonColor: undefined,
                        cancelButtonText: "OK",
                    },
                });
            }
        });
        Object.defineProperty(this, "hideDialog", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    isOpen: false,
                    props: {},
                });
            }
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                isOpen: false,
                props: {},
                showDialog: this.showDialog,
                showErrorDialog: this.showErrorDialog,
                hideDialog: this.hideDialog,
            }
        });
        Object.defineProperty(this, "toggleOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_, reason) => {
                const { disableBackdropClick } = this.props;
                if (!(disableBackdropClick && reason === "backdropClick")) {
                    this.setState({
                        isOpen: false,
                    });
                }
            }
        });
        Object.defineProperty(this, "close", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ isOpen: false });
            }
        });
    }
    render() {
        const { buttons, cancelButtonColor, cancelButtonText, confirmButtonColor, confirmButtonText, disableEscapeKeyDown, html, onCancel, onConfirm, size, text, title, } = this.state.props;
        let { content } = this.state.props;
        if (!content && html) {
            content = (React.createElement(DialogContentText, { id: "alert-dialog-description" },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: html } })));
        }
        if (!content) {
            content = (React.createElement(DialogContentText, { id: "alert-dialog-description", style: {
                    whiteSpace: "pre-line",
                } },
                React.createElement(Typography, { component: "span", variant: "body2" }, text !== null && text !== void 0 ? text : "")));
        }
        return (React.createElement(DialogServiceContext.Provider, { value: this.state },
            this.props.children,
            React.createElement(CoreDialog, { disableEscapeKeyDown: disableEscapeKeyDown !== null && disableEscapeKeyDown !== void 0 ? disableEscapeKeyDown : false, fullWidth: !!size, maxWidth: size !== null && size !== void 0 ? size : undefined, onClose: this.toggleOpen, open: this.state.isOpen },
                React.createElement(DialogTitle, { id: "alert-dialog-title" }, title !== null && title !== void 0 ? title : ""),
                React.createElement(DialogContent, null, content),
                React.createElement(DialogActions, null, buttons ? (buttons.map((button, indx) => {
                    var _a;
                    return (React.createElement(Button, { key: indx, color: (_a = button.color) !== null && _a !== void 0 ? _a : "primary", onClick: button.action }, button.text));
                })) : (React.createElement(React.Fragment, null,
                    React.createElement(Button, { color: cancelButtonColor !== null && cancelButtonColor !== void 0 ? cancelButtonColor : undefined, onClick: onCancel !== null && onCancel !== void 0 ? onCancel : this.close }, cancelButtonText !== null && cancelButtonText !== void 0 ? cancelButtonText : "Cancel"),
                    onConfirm && (React.createElement(Button, { color: confirmButtonColor !== null && confirmButtonColor !== void 0 ? confirmButtonColor : "primary", onClick: onConfirm }, confirmButtonText !== null && confirmButtonText !== void 0 ? confirmButtonText : "Confirm"))))))));
    }
}
Object.defineProperty(DialogServiceProvider, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        disableEscapeKeyDown: false,
        disableBackdropClick: false,
        title: "Alert",
        confirmButtonColor: "primary",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
    }
});
export const withDialog = (Component) => {
    const WrappedComponent = React.forwardRef((props, ref) => (React.createElement(DialogServiceContext.Consumer, null, (context) => (React.createElement(Component, Object.assign({}, props, { ref: ref, hideDialog: context.hideDialog, showDialog: context.showDialog, showErrorDialog: context.showErrorDialog }))))));
    WrappedComponent.displayName = "WrappedDialogComponent";
    hoistNonReactStatics(WrappedComponent, Component);
    return WrappedComponent;
};
