import * as React from "react";
import * as Sentry from "@sentry/react";
import { BroadcastChannel } from "broadcast-channel";
import * as ReactDOM from "react-dom/client";
import AppConfig, { IGNORABLE_ERRORS } from "@app/util/AppConfig";
import { getReadableErrorMessage } from "@app/util/Utils";
import MainApp from "./App";
const SENTRY_DSN = AppConfig.env.sentryDSN;
if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration()],
        ignoreErrors: IGNORABLE_ERRORS,
    });
    // override the behavior of console.error to play nice with Sentry
    const standardErrorFunc = (() => console.error)();
    console.error = (error, ...args) => {
        const errorMessage = getReadableErrorMessage(error);
        standardErrorFunc(errorMessage, ...args);
    };
}
const logoutChannel = new BroadcastChannel("logout");
logoutChannel.postMessage("logout");
const rootEl = document.getElementById("root");
const root = ReactDOM.createRoot(rootEl);
root.render(React.createElement(MainApp, null));
