import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { parseDate } from "./date-utils";
// fixes an error that started happening in SellerReportSidebarContent.tsx
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * Behaves exactly like the `dayjs` constructor, except
 * parses dates to the current year by default (rather than 2001,
 * which seems to be what `dayjs` does)
 *
 * @param params
 * @returns
 */
const dayjs_ = (...params) => {
    const input = params[0];
    if (input && typeof input === "string" && input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
        return dayjs(input);
    }
    const dateParsed = input && typeof input === "string" ? parseDate(input) : null;
    if (dateParsed &&
        dateParsed.getFullYear() === 2000 &&
        !String(input).includes("2000") &&
        !String(input).includes("2001")) {
        dateParsed.setFullYear(new Date().getFullYear());
    }
    if (input && typeof input === "string" && !dateParsed) {
        return dayjs("Invalid Date");
    }
    if (dateParsed) {
        // eslint-disable-next-line no-param-reassign
        params[0] = dateParsed;
    }
    const date = dayjs(...params);
    if (date.isValid()) {
        // https://github.com/iamkun/dayjs/issues/1902
        // https://github.com/iamkun/dayjs/issues/1251
        if (date.year() === 2001 && !String(input).includes("2001")) {
            return date.set("year", dayjs().year());
        }
    }
    return date;
};
dayjs_.tz = dayjs.tz;
export default dayjs_;
