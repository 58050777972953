import parser from "any-date-parser";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
/**
 * Parse a date in virtually any format
 *
 * NOTE: This isn't great at handling timezones. It'll think
 * something like "2023-10-01T12:00:00Z" is in local time.
 * This method is best used when handling dates input by users,
 * NOT well-formatted dates returned from our database, which
 * can be handled directly by dayjs.
 *
 * @param dateString
 * @returns
 */
export const parseDate = (dateString) => {
    const date = parser.fromString(dateString, "en-US");
    if (!date.invalid) {
        // console.log("TEST: parsing date", dateString, date);
        return date;
    }
    return null;
};
/**
 * Convert a UTC date string to local time
 *
 * @param date The date to convert
 * @param options Defines the timezone and format to which the date should be converted
 * @returns
 */
export const convertUtcDateString = (date, options) => {
    var _a, _b;
    const tz = (_a = options === null || options === void 0 ? void 0 : options.tz) !== null && _a !== void 0 ? _a : "America/New_York";
    const format = (_b = options === null || options === void 0 ? void 0 : options.format) !== null && _b !== void 0 ? _b : "YYYY-MM-DDTHH:mm:ssZ";
    const dateStr = typeof date === "string" ? date : date.toISOString();
    return dayjs.utc(dateStr).tz(tz).format(format);
};
