import { createTheme } from "@mui/material";
import AppConfig from "@app/util/AppConfig";
const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: AppConfig.themeColors.primary,
        },
        secondary: {
            main: AppConfig.themeColors.secondary,
        },
        error: {
            main: AppConfig.themeColors.danger,
        },
        info: {
            main: AppConfig.themeColors.grey,
        },
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    zIndex: 1000,
                    background: "#fafbfc",
                    "& .MuiListItem-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: "14px !important",
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    maxWidth: "1280px !important",
                },
                maxWidthMd: {
                    maxWidth: "960px !important",
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    backgroundColor: "inherit",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardInfo: {
                    backgroundColor: "rgb(232, 244, 253)",
                },
                icon: {
                    color: "#2196f3 !important",
                },
            },
        },
        // @ts-expect-error 'MUIDataTableHeadCell' does not exist in type 'Components<Omit<Theme, "components" | "palette"> & CssVarsTheme>'
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                },
                sortAction: {
                    fontWeight: "bold",
                },
            },
        },
        MUIDataTableBodyCell: {
            styleOverrides: {
                root: {
                    overflowY: "hidden",
                },
            },
        },
    },
    typography: {
        fontFamily: `'${AppConfig.fonts.primary}', Helvetica, Arial, sans-serif`,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontSize: 11,
    },
    spacing: 4,
});
export default theme;
