import memoize from "lodash/memoize";
const asDict = (user) => {
    var _a;
    return {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        roles: (_a = user.roles) !== null && _a !== void 0 ? _a : [],
    };
};
const asDictMemoized = memoize(asDict);
class User {
    constructor(user) {
        var _a;
        Object.defineProperty(this, "hasRole", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (role) => {
                const _roles = Array.isArray(role) ? role : [role];
                return (_roles.filter((_role) => { var _a; return !!((_a = this.roles) === null || _a === void 0 ? void 0 : _a.map((r) => r.toLowerCase().trim()).includes(_role.toLowerCase().trim())); })
                    .length > 0);
            }
        });
        this.uid = user.uid;
        this.first_name = user.first_name;
        this.last_name = user.last_name;
        this.email = user.email;
        this.phone = user.phone;
        this.roles = user.roles;
        this.icon_url = user.icon_url;
        this.office = user.office;
        this.profile = (_a = user.profile) !== null && _a !== void 0 ? _a : user.user;
    }
    /**
     * `true` if the user is signed in with a DASH account
     */
    get isInternal() {
        const internalDomains = ["dashnc.com", "pignc.com", "avldash.com", "longleaftitle.com"];
        return !!internalDomains.find((domain) => { var _a; return (_a = this.email) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(`@${domain}`); });
    }
    /**
     * `true` if the user is a developer
     * Developers sometimes have access to hidden / "beta" features
     */
    get isDeveloper() {
        return ((this.email &&
            ["developer@dashnc.com", "scripts@dashnc.com", "josh@dashnc.com"].includes(
            // TODO: this shouldn't be hard-coded... we should create a `developer` role.
            this.email.toLowerCase().trim())) ||
            false);
    }
    get name() {
        return [this.first_name, this.last_name].filter((obj) => obj).join(" ");
    }
    get initials() {
        return [this.first_name, this.last_name]
            .filter((obj) => obj)
            .map((obj) => (obj ? obj.substring(0, 1) : ""))
            .join("");
    }
    get asDict() {
        return asDictMemoized(this);
    }
}
export default User;
