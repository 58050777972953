import User from "@app/classes/user";
const activeUser = window._DASH_ACTIVE_USER ? new User(window._DASH_ACTIVE_USER) : null;
const viewingAsUser = window._DASH_VIEWING_AS_USER
    ? new User(window._DASH_VIEWING_AS_USER)
    : activeUser;
const Session = {
    activeUser,
    viewingAsUser,
};
export default Session;
