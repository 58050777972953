var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import styled from "styled-components";
/**
 * When the platform is Windows, wraps the frontend in a container which renders
 * more elegant scrollbars than Windows does by default.
 */
let WindowsScrollbarWrapper = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement("div", Object.assign({}, props, { style: Object.assign({ height: "100%" }, props.style) }), children));
};
if (["Win32", "Win16"].includes(navigator.platform)) {
    WindowsScrollbarWrapper = styled(WindowsScrollbarWrapper) `
      height: 100%;

      & ::-webkit-scrollbar {
        display: none;
        width: 8px;
        height: 8px;
      }

      & ::-webkit-scrollbar-track {
        visibility: hidden;
      }

      & *:hover::-webkit-scrollbar {
        display: unset;
      }

      & *:hover::-webkit-scrollbar-thumb {
        background: #bbb;
        border-radius: 4px;
      }
  `;
}
export default WindowsScrollbarWrapper;
